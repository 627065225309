<template>
        <!-- Middle Pic Offset  -->
  <!-- <div class="eventBanner h-48 m-4 dupr md:absolute hidden rounded-lg border-8  shadow-sm  md:flex md:justify-end  bg-slate-50 ">        
    <div class="w-4/5 justify-center mt-5 p-5 text-3xl">      

    </div>       
   
     
  </div>   -->
  <div class="col-span-full h-12 bg-walter-primary">
    <img src="../assets/EventPage.svg" class="aspect-square pt-16 w-45 h-30 " alt="DUPR iOS App">
  </div>
  

</template>

<script>
export default {
    data(){ 
      return {
        clinics : [
          {name: "Live Ball Fridays 11:30 El Camino Country Club"}          
        ]
      }
    }
}

</script>

<style>
.nature{
  background-color: white;
  color: rgb(224, 46, 46);
  font-size: 25vw; 
  font-weight: bold;
  margin: 0 auto;
  padding: 10px;
  width: 50%;
  text-align: center;
   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode:saturation;
}
.club{
  color: #FFFFFF;

  text-shadow: 2px 2px 0 #4074b5, 2px -2px 0 #4074b5, -2px 2px 0 #4074b5, -2px -2px 0 #4074b5, 2px 0px 0 #4074b5, 0px 2px 0 #4074b5, -2px 0px 0 #4074b5, 0px -2px 0 #4074b5;
}
.glow { 

  font-family: 'Concert One', 'Times New Roman', serif
}

.eventBanner{
  left: 10%;
  top: 60%;
  width: 70%;
}


        
</style>